import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src430903205/src/dashbot-home-gatsby/src/layouts/blog/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Want to know more about the context in which voice assistants are used?`}<br parentName="p"></br>{`
`}{`We have compiled the key facts and figures of existing studies in this quick primer.`}</p>
    <h2>{`Smartphones and cars drive volumes but at-home smart speakers drive engagement`}</h2>
    <p>{`Smartphones and cars top the numbers of monthly users. This is somewhat logical when considering that:`}<br parentName="p"></br>{`
`}{`– most smartphones and car natively embed voice assistants`}<br parentName="p"></br>{`
`}{`– the penetration of smartphones and car among the population is very high`}<br parentName="p"></br>{`
`}{`– smartphones are following their owner everywhere`}<br parentName="p"></br>{`
`}{`– Americans spend in average 54 minutes in their car every day`}<br parentName="p"></br>{`
`}{`– voice was available in cars as early as 2004, 10 years before the first smart speaker`}</p>
    <p>{`However, at home users are much more engaged with smart speakers: these are way in the lead when it comes to the proportion of daily use.`}</p>
    <p><img parentName="p" {...{
        "src": "https://i.dashbot.io/wp-home/uploads/2019/06/20161415/Screen-Shot-2019-06-20-at-4.13.44-PM-1024x364.png",
        "alt": null
      }}></img></p>
    <h2>{`Top use cases vary depending on the context`}</h2>
    <p><img parentName="p" {...{
        "src": "https://i.dashbot.io/wp-home/uploads/2019/06/20161743/Screen-Shot-2019-06-20-at-4.17.19-PM-768x388.png",
        "alt": "Top 3 usages by context"
      }}></img></p>
    <h2>{`Different contexts, different ecosystems`}</h2>
    <p><img parentName="p" {...{
        "src": "https://i.dashbot.io/wp-home/uploads/2019/06/20163519/Screen-Shot-2019-06-20-at-4.34.58-PM.png",
        "alt": "Voice ecosystem by context"
      }}></img></p>
    <h2>{`At home, some locations are preferred for smart speakers`}</h2>
    <ul>
      <li parentName="ul">{`44% in the living room`}</li>
      <li parentName="ul">{`38% in the bedroom`}</li>
      <li parentName="ul">{`33% in the kitchen`}</li>
    </ul>
    <h2>{`The car is a complex context for voice interactions`}</h2>
    <p>{`The specifics of driving impact the conversation paths and content :`}</p>
    <ul>
      <li parentName="ul">{`Safety comes first, conversation second: at anytime the user might need to quit the conversation and will come back to it later.`}</li>
      <li parentName="ul">{`The need for offline use is likely (e.g. in an underground garage, a remote area…)`}</li>
      <li parentName="ul">{`The physical environment is challenging for voice recognition because of cabin noise and microphone positioning`}</li>
      <li parentName="ul">{`Some use cases are car-specific (e.g. cabin controls, questions about the surroundings)`}</li>
    </ul>
    <h2>{`The multi-layered in-car ecosystem pushes for cross-assistants conversations capabilities`}</h2>
    <ul>
      <li parentName="ul">{`76% of drivers are interested in having their in-home voice service in their next vehicle because`}
        <ul parentName="li">
          <li parentName="ul">{`There is no learning curve for the user (66%)`}</li>
          <li parentName="ul">{`There is no learning curve for the voice assistant (57%)`}</li>
          <li parentName="ul">{`They want seamless experience (49%)`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Tesla registers the worst conversion rate (50%) from trial to monthly use of voice in car because it does not support Apple CarPlay and Android Auto`}</li>
    </ul>
    <h2>{`Voice capabilities: an increasingly high stake for car makers`}</h2>
    <ul>
      <li parentName="ul">{`59% of drivers are more likely to buy from a specific car manufacturer if their in-home voice service is available in their car`}</li>
      <li parentName="ul">{`74% among Gen Y`}</li>
      <li parentName="ul">{`79% among Gen Z`}</li>
    </ul>
    <h2>{`Sources`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://voicebot.ai/in-car-voice-assistant-consumer-adoption-report-2019/"
        }}><em parentName="a">{`Voicebot.ai – In-car voice assistant consumer adoption report – January 2019`}</em></a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://voicebot.ai/voice-assistant-consumer-adoption-report-2018/"
        }}><em parentName="a">{`Voicebot.ai – Voice Assistant Consumer Adoption Report – June 2018`}</em></a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.slideshare.net/adobe/state-of-voice-assistants-2019"
        }}><em parentName="a">{`State of Voice by Adobe Digital – 2019`}</em></a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://developer.amazon.com/blogs/alexa/post/4688f6ea-40ab-4cc0-a5e7-313f5735366a/j-d-power-voice-is-a-deciding-factor-in-the-vehicle-purchase-decision"
        }}><em parentName="a">{`Voice in Vehicles by J.D. Power for Alexa Auto – 2019`}</em></a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://voicebot.ai/2019/04/15/voice-assistants-in-the-car-alexa-auto-nuance-harman-voicebot-podcast-ep-92/"
        }}><em parentName="a">{`Adam Emfield, Nuance Automotive in Voicebot Podcast 04/15/2019`}</em></a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://voicebot.ai/2019/04/15/voice-assistants-in-the-car-alexa-auto-nuance-harman-voicebot-podcast-ep-92/"
        }}><em parentName="a">{`Arianne Walker, Amazon Alexa Automotive in Voicebot Podcast 04/15/2019`}</em></a></li>
    </ul>
    <h2>{`Want more?`}</h2>
    <ul>
      <li parentName="ul">{`Watch the replay of our June 2019 meetup on the context of voice with Lisa Falkson and Mihai Antonescu`}</li>
      <li parentName="ul">{`Download our `}<a parentName="li" {...{
          "href": "https://i.dashbot.io/wp-home/uploads/2019/06/20131751/20190619_Dashbot_FactSheet_ContextOfVoice.pdf"
        }}>{`2 pager pdf recap`}</a>{` on the context of voice.`}</li>
    </ul>
    <h2>{`About Dashbot`}</h2>
    <p><em parentName="p"><a parentName="em" {...{
          "href": "https://www.dashbot.io/"
        }}>{`Dashbot`}</a>{` is a conversational analytics platform that enables enterprises and developers to understand user behaviors, optimize response effectiveness, and increase user satisfaction through actionable data and tools.`}</em></p>
    <p><em parentName="p">{`In addition to traditional analytics like engagement and retention, we provide chatbot specific metrics including NLP response effectiveness, sentiment analysis, conversational analytics, and the full chat session transcripts.`}</em></p>
    <p><em parentName="p">{`We also have tools to take action on the data, like our live person take over of chat sessions and broadcast messaging for re-engagement.`}</em></p>
    <p><em parentName="p">{`We support Alexa, Google Home, Facebook Messenger, Slack, Twitter, Kik, SMS, web chat, and any other conversational interface.`}</em></p>
    <p><a parentName="p" {...{
        "href": "https://www.dashbot.io/contact/sales/?ref=blog+primer+context+of+voice"
      }}><br parentName="a"></br>
        {`Contact us for a demo `}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      